import { Cascader, Form, Modal, Select, Tag, TreeSelect } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import styles from './index.less';
import type { AnchorTagsModalStore } from './model.ts';
const { SHOW_CHILD } = Cascader;

const AnchorTagsModal = ({ store }: { readonly store: AnchorTagsModalStore }) => {
  const { visible, onClose, setRef, onSubmit, categories, onChange, selectedOptions, onTagDelete, userVo } = store;

  const renderTags = React.useMemo(() => {
    return selectedOptions.map((option) => {
      return (
        <Tag
          closable={true}
          color="processing"
          key={option.id}
          className={'h-5.5 items-center text-12 text-primary border-[#ADC6FF]'}
          onClose={() => onTagDelete?.(option.id)}
        >
          {option.name}
        </Tag>
      );
    });
  }, [selectedOptions, onTagDelete]);
  return (
    <Modal
      title={userVo?.length > 1 ? '批量设置主播标签' : '设置主播标签'}
      open={visible}
      onCancel={onClose}
      maskClosable={false}
      onOk={onSubmit}
    >
      {/*     <div className={`${styles.tags} py-2`}>{renderTags}</div>
      <div className="text-heading mb-4">主播标签</div>*/}
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        ref={setRef}
      >
        <Form.Item
          name={'libId'}
          rules={[{ required: true, message: '请选择主播标签' }]}
        >
          <Cascader
            options={categories}
            placeholder="请选择"
            onChange={onChange}
            multiple={true}
            maxTagCount="responsive"
            showCheckedStrategy={SHOW_CHILD}
            fieldNames={{
              label: 'name',
              value: 'id',
              children: 'children',
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default observer(AnchorTagsModal);
