import { Form, Input, Modal, Radio, Select } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import type { AuditModalStore } from './model.ts';

const AuditModal = ({ store }: { readonly store: AuditModalStore }) => {
  const {
    visible,
    onClose,
    setRef,
    onSubmit,
    isAgree,
    changeAgreeStatus,
    refuseReasonOptions,
    changeRefuseReason,
    isInputReason,
  } = store;
  return (
    <Modal
      title="审核主播完整简历"
      open={visible}
      onCancel={onClose}
      maskClosable={false}
      onOk={onSubmit}
    >
      <div>注意：是审核主播简历全部信息，包括切片、形象照、文本信息全部一起审核</div>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        ref={setRef}
        initialValues={{ status: 1 }}
      >
        <Form.Item
          label="是否通过"
          name={'status'}
        >
          <Radio.Group
            defaultValue={1}
            onChange={changeAgreeStatus}
          >
            <Radio value={1}>通过</Radio>
            <Radio value={2}>不通过</Radio>
          </Radio.Group>
        </Form.Item>
        {!isAgree && (
          <Form.Item
            label="原因"
            name={'auditReason'}
            rules={[{ required: true, message: '请选择不通过原因' }]}
          >
            <Select
              options={refuseReasonOptions}
              placeholder={'请选择'}
              onChange={changeRefuseReason}
            />
          </Form.Item>
        )}
        {!isAgree && isInputReason && (
          <Form.Item
            wrapperCol={{ offset: 4 }}
            name={'remark'}
            rules={[{ required: true, message: '请输入不通过原因' }]}
          >
            <Input.TextArea
              placeholder="请输入"
              autoSize={{
                minRows: 4,
                maxRows: 4,
              }}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
export default observer(AuditModal);
