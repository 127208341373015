import AnchorInfoModal from '@/compnents/anchorInfo';
import ModelCard from '@/pages/section/sectionAdmin/components/modelCard.tsx';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { SearchListStructure } from '../../../utils';
import AnchorTagsModal from './components/anchorTags';
import AuditModal from './components/audit';
import Model from './model.tsx';

const AnchorManagement = () => {
  const store = useMemo(() => new Model(), []);
  const { mainStore, auditModalStore, anchorTagsModalStore, anchorInfoModalStore } = store;

  return (
    <>
      <SearchListStructure store={mainStore} />
      <AuditModal store={auditModalStore} />
      <AnchorTagsModal store={anchorTagsModalStore} />
      <AnchorInfoModal store={anchorInfoModalStore} />
      <ModelCard store={store.myModelCardStore} />
    </>
  );
};

export default observer(AnchorManagement);
