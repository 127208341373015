import { API } from '@/pages/anchor/anchorManagement/api.ts';
import { type BaseData, request } from '@/utils';
import { api } from '@/utils/api.ts';
import { message } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import { action, observable } from 'mobx';

export class AuditModalStore {
  @observable public visible = false;

  @observable public isAgree = true;
  @observable public isInputReason = false;

  @observable public formRef: FormInstance;

  @observable public userId = null;
  @observable public parent = null;
  @observable public refuseReasonOptions = [];
  constructor(parent) {
    this.parent = parent;
    this.init();
  }
  init = async () => {
    const dictMaps = await request<BaseData<any>>({
      url: api.system.getDictMap,
      method: 'post',
      data: {
        dictTypeList: ['vita_audit_refuse_reason'],
      },
    });
    //拒绝原因
    const refuseReasonList = dictMaps.data?.vita_audit_refuse_reason?.map((e) => {
      return { label: e.dictName, value: e.dictValue };
    });
    this.refuseReasonOptions = refuseReasonList;
  };
  @action
  changeAgreeStatus = () => {
    this.isAgree = !this.isAgree;
  };
  @action
  changeRefuseReason = (value: any, options: any) => {
    this.isInputReason = value === '99';
  };

  @action
  setRef = (ref: FormInstance) => {
    this.formRef = ref;
  };

  @action
  onShow = (userId: string) => {
    this.userId = userId;
    this.visible = true;
  };

  @action
  onClose = () => {
    console.log('onClose');
    this.visible = false;
  };

  @action
  onSubmit = async () => {
    const isValidate = await this.formRef.validateFields();
    if (!isValidate) {
      return;
    }
    const values = this.formRef.getFieldsValue();
    let auditReason = values.auditReason;
    if (values.auditReason && values.auditReason === '99') {
      auditReason = values.remark;
    } else {
      auditReason = this.refuseReasonOptions.find((e) => e.value === values.auditReason)?.label;
    }
    await request({
      url: API.auditVita,
      method: 'post',
      data: { userId: this.userId, isAuditUserAllInfo: 1, ...values, auditReason },
    });
    this.onClose();
    message.success('审核成功');
    this.parent?.mainStore.grid.onRefresh();
  };
}
